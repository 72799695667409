<template>
  <div class="loan">
    <div class="top-box-area">
      <div class="top-box-area-header">
        <div>
          <img @click="back" class="back-icon" src="@/assets/images/compoundLoan/back-icon-white.png" alt="">
          <span class="title">CashAgad</span>
        </div>
        <span class="serivce" @click="openService"></span>
      </div>
    </div>
    <div class="main-container">
      <div class="money-box">

        <span class="title">{{ $t('loan.fudaijine') }}(PHP)</span>
        <ul class="product-list">
          <li v-for="(item, index) in sys_plan_list" :class="{
            selected: index == selectIndex,
            locked: !item.Agifyo_ndecita
          }" @click="productSelect(item, index)">
            <p> {{ getmoney(item.ukufumyaIfingi) }}</p>

          </li>
        </ul>
        <!-- 借款天数 -->
        <p class="loan-day"> {{ $t('loan.jiekuanriqi') }} <span class="dayclass">{{ dip_loanDay }} {{ $t('loan.day')
        }}</span>
        </p>
        <!-- 第一期借款天数 -->
        <p class="firstday">{{ $t('loan.firstloanday') }}</p>
        <ul class="loanday-ul">
          <li :class="{ 'active-li': index == loanDayIndex }" v-for="(item, index) in dayArr" :key="index"
            @click="handleLoanDay(item, index)">{{ item.tgio }} {{ $t('loan.day') }}</li>
        </ul>
      </div>
      <div class="content-box">
        <submit-btn :submit-flag="true" :subText="$t('loan.lijishenqing')" @submit="submit()" />
        <p class="text-style">{{ $t('loan.anshihuankuan') }}</p>
        <div class="msgs" v-if="dataInfo.Inyimboo">
          <van-notice-bar left-icon="volume-o" :text="dataInfo.Inyimboo" />
        </div>
        <Banner class="banner-box" :bannerList="bannerList" v-if="bannerList.length > 0" />
        <p class="footer-text">{{ $t('loan.loanTerm') }}</p>
      </div>
    </div>

    <!-- <loanDetail :visbile.sync="visbile" :moneyNum="moneyNum" :timers="loanDay" @close="visbile = false"></loanDetail> -->
  </div>
</template>

<script>

import { getStayPayList } from '@/api/axios'
import Banner from '@/components/banner.vue'
import loanDetail from './components/loanDetail.vue'
export default {
  components: {
    Banner, loanDetail
  },
  data() {
    return {
      visbile: false,
      notification_msg: '',
      bannerList: [],
      selectIndex: '',//数组中最大金额的索引值
      loanDay: 0,//借款天数
      dip_loanDay: 0,//借款期限
      loanDayIndex: 0,//借款天数索引值
      sys_plan_list: [],
      dataInfo: {},

    }
  },
  computed: {
    moneyNum() {
      let a = 0
      if (this.selectIndex !== '') {
        a = this.sys_plan_list[this.selectIndex] ? this.sys_plan_list[this.selectIndex].ukufumyaIfingi : 0
      }
      return a
    },
    dayArr() {
      let a = []
      if (this.selectIndex !== '') {
        a = this.sys_plan_list[this.selectIndex] ? this.sys_plan_list[this.selectIndex].Ukuftashi : []
      }
      return a
    }
  },
  watch: {
    selectIndex: {
      handler(val) {
        console.log(this.selectIndex !== '', 333, this.sys_plan_list, val, this.sys_plan_list[val]);
        if (val !== '') {
          console.log(val, 'val的覆盖广泛发生');
          let arr = this.sys_plan_list[val]?.Ukuftashi || []
          const arr1 = arr.map((item) => item.tgio);
          this.loanDay = Math.max.call(null, ...arr1);
          this.loanDayIndex = arr.findIndex(item => item.tgio == this.loanDay)
          this.dip_loanDay = arr[this.loanDayIndex].dip_tgio
        }
      },
      immediate: false,
      deep: true
    },
    visbile: {
      handler(val) {
        if (val) {
          this.$H5Object.isShowBottomTabinter(false)
        } else {
          this.$H5Object.isShowBottomTabinter(true)
        }
      },
      immediate: true
    }
  },
  created() {
    this.getStayPayList()
    this.$H5Object.canReloadHtml(false)
    this.$H5Object.changeStatusBarBgColor('#005FE9')
    this.$H5Object.changeStatusIsDarkFont(false)
    this.$H5Object.isShowBottomTabinter(false)
    window.eaH5PageBack = this.back
    window.refreshPages = this.handlerRefreshPages
  },
  mounted() {

  },
  methods: {
    // 打开客服
    openService() {
      this.$H5Object.goCustomServicePage()
    },
    // 刷新页面
    handlerRefreshPages() {
      this.getStayPayList()
    },

    goback() {
      if (this.visbile) {
        this.visbile = false
      } else {
        this.$H5Object.cancleView()
      }

    },
    // 获取最大值的下标
    getMaxVal(arr, field) {

      const arr1 = arr.map((item) => item[field]);
      const max = Math.max.call(null, ...arr1);
      console.log(max, "最大金额")
      return arr.findIndex(item => item[field] == max)
    },
    // 点击加减
    handleMoney(type) {
      if (type == 'reduce') {
        this.selectIndex ? this.selectIndex-- : 0
      }
      if (type == 'plus') {
        this.selectIndex != this.sys_plan_list.length - 1 ? this.selectIndex++ : this.sys_plan_list.length - 1
      }
      this.$store.state.settings.loanpoint.money_updatecount += 1
      this.$store.state.settings.loanpoint.money_num += 1
      console.log(this.selectIndex, this.sys_plan_list[this.selectIndex])
    },

    //
    productSelect(item, index) {
      if (!item.Agifyo_ndecita) {
        this.$toast(this.$t('stayPay.upAmount'))
        return
      }
      this.selectIndex = index
      this.$store.state.settings.loanpoint.money_updatecount += 1
      this.$store.state.settings.loanpoint.money_num += 1
    },
    // 点击借贷天数
    handleLoanDay(item, index) {
      this.loanDay = item.tgio
      this.dip_loanDay = item.dip_tgio
      this.loanDayIndex = index
      this.$store.state.settings.loanpoint.term_updatecount += 1
      this.$store.state.settings.loanpoint.term_num += 1
    },
    // 获取首页数据
    async getStayPayList() {
      const res = await getStayPayList() || {}
      this.dataInfo = res.data
      this.sys_plan_list = res.data?.Ukutantika_bwangu || []
      console.log(this.sys_plan_list, '11112323ssss');
      this.bannerList = res.data?.amaBanner || []
      this.bannerList.forEach((item) => {
        item.url = item.amaBanner_url
      })
      console.log(this.bannerList, "banner列表")
      this.selectIndex = this.sys_plan_list.findIndex((item, index, arr) => {
        return item.Agifyo_ndecita && !arr[index + 1].Agifyo_ndecita
      })
      // this.$nextTick(() => {
      //   this.selectIndex = this.getMaxVal(this.sys_plan_list, 'ukufumyaIfingi')

      // })
    },
    // 提交
    submit() {
      this.$toast(this.$t('stayPay.repaymentFirst'))
      // this.visbile = true
      // this.$store.state.settings.loanpoint.apply_updatecount += 1
      // this.$store.state.settings.loanpoint.apply_num += 1
    },
    back() {
      this.$router.back()
    }

  }
}
</script>
<style lang="scss" scoped>
.app-content {
  background: #fff !important;
}

/deep/ .van-popup--bottom {
  left: 0 !important;
}

.loan {
  overflow: hidden;

  .top-box-area {
    height: 500px;
    padding: 22px 40px;
    margin-top: 88px;
    background: url('~@/assets/images/compoundLoan/top-bg-icon.png') no-repeat right top #005FE9FF;
    background-size: 482px 380px;

    .top-box-area-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 88px;
      background: #005FE9;

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      z-index: 20;

      .back-icon {
        width: 44px;
        height: 44px;
      }

      .title {
        margin-left: 8px;
        font-size: 56px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 84px;
      }

      .serivce {
        display: block;
        width: 44px;
        height: 44px;
        background: url('~@/assets/images/public/serivce-icon.png') no-repeat center center;
        background-size: 100%;
      }
    }
  }

  .main-container {
    padding: 0 40px;
    position: relative;
    top: -216px;
  }

  .money-box {
    padding: 64px 48px 48px;
    background: #1C1D21 url('~@/assets/images/compoundLoan/main-bg.png') no-repeat top;
    background-size: 100% 558px;
    border-radius: 32px;
    min-height: 558px;
    margin-bottom: 24px;

    .title {
      display: block;
      font-size: 28px;
      font-weight: 400;
      color: #CCCCCC;
      margin-bottom: 32px;


    }

    .product-list {
      display: flex;
      flex-wrap: wrap;

      li {
        width: calc(33% - 16px);
        height: 76px;
        background: #ffffff;
        border-radius: 16px;
        margin-bottom: 24px;
        margin-right: 24px;

        &:nth-of-type(3n) {
          margin-right: 0;
        }

        p {
          font-size: 28px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #1C1D21;
          line-height: 76px;
          text-align: center;
        }
      }

      .selected {
        background: #F7B014;

        p {
          color: #fff;
        }
      }

      .locked {
        background: #666;

        p {
          color: #fff;
        }
      }

    }

    .loan-day {
      font-size: 28px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #CCCCCC;
      line-height: 36px;
      margin-top: 24px;
    }

    :deep(.dayclass) {
      font-size: 28px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
      margin-left: 16px;
    }

    .firstday {
      font-size: 28px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #CCCCCC;
      line-height: 36px;
      margin-top: 32px;
    }

    .loanday-ul::-webkit-scrollbar {
      display: none
    }

    .loanday-ul {
      display: flex;
      flex-wrap: wrap;
      height: 80px;
      overflow-y: auto;
      margin-top: 30px;

      li {
        width: 160px;
        height: 76px;
        background: #666;
        border-radius: 16px;
        font-size: 28px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 76px;
        text-align: center;
        margin-right: 24px;

      }

      li:nth-child(3*n) {
        margin-right: 0px;
      }

      .active-li {
        background: #F7B014;
      }
    }

  }

  .content-box {
    width: 670px;
    margin: 0 auto;
    margin-bottom: 250px;

    .text-style {
      margin-top: 24px;
      padding: 0 48px;
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #B3B3B3;
      line-height: 36px;
    }

    .msgs {
      margin-top: 40px;

      :deep(.van-notice-bar) {
        height: 124px;
        background: #F4F5FC;
        border-radius: 32px;
        padding: 0 40px;

        .van-notice-bar__left-icon {
          width: 60px;

          &::before {
            display: none;
          }

          &::after {
            content: '';
            position: absolute;
            width: 44px;
            height: 44px;
            left: 0;
            top: 50%;
            margin-top: -22px;
            background: url('~@/assets/images/public/notice-icon.png') no-repeat center center;
            background-size: 100%;
          }
        }

        .van-notice-bar__content {
          font-size: 28px;
          font-weight: 400;
          color: #1C1D21;
        }
      }
    }

    .banner-box {
      margin-top: 40px;
    }

    .footer-text {
      margin: 40px auto;
      width: 538px;
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #B3B3B3;
      line-height: 36px;

    }
  }

  :deep(.btn-box) {


    .submit-btn.can-submit-btn {
      background: #005FE9;
      color: #FFFFFF;
    }
  }

}
</style>
